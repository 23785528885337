










































































































































































import { Component, Vue } from 'vue-property-decorator';
import CCCallout from '@/components/CCCallout.vue';
import { i18n } from '@/i18n';
import date from 'quasar/src/utils/date.js';;
import { apiGet } from '@/store';
import config from '@/config';
import axios from 'axios';


@Component({
  components: {
    CCCallout,
  },
})
export default class DataExport extends Vue {
  public $route: any;
  public createForm: boolean = false;
  public createExportLoading: boolean = false;
  public formDtFrom: string = '2023/01/01';
  public formDtTo: string = '2023/02/01';
  public formAggregation: string | null = null;
  public formExportType: string = 'csv';
  public formPollutants: string[] = ['co2', 'temperature', 'humidity', 'cov', 'no2', 'pm25', 'pm10'];
  public treeSelected: string[] = [];
  public treeTicked: string[] = [];
  public treeExpanded: string[] = [];
  public dataExports: any[] = [];
  public exportWithDetails: any = {};

  public aggregationsOptions = [
    { label: 'Aucune', value: null },
    { label: '10 minutes', value: '10m' },
    { label: '30 minutes', value: '30m' },
    { label: '1 heure', value: '1h' },
    { label: '1 jour', value: '24h' },
  ];

  public pollutantsOptions = [
    { value: 'co2', label: i18n.t('FIELD_CO2_SHORT') },
    { value: 'temperature', label: i18n.t('FIELD_TEMPERATURE') },
    { value: 'humidity', label: i18n.t('FIELD_HUMIDITY') },
    { value: 'cov', label: i18n.t('FIELD_COV_SHORT') },
    { value: 'no2o3', label: i18n.t('FIELD_NO2O3_SHORT') },
    { value: 'pm25', label: i18n.t('FIELD_PM25_SHORT') },
    { value: 'pm10', label: i18n.t('FIELD_PM10_SHORT') },
  ];

  public exportTypeOptions = [
    { value: 'csv', label: 'CSV' },
    { value: 'tsv', label: 'CSV (avec tabulation)' },
    { value: 'xlsx', label: 'Excel' },
  ];

  get aggregationSeconds() {
    if (this.formAggregation === '10m') { return 10 * 60; }
    if (this.formAggregation === '30m') { return 30 * 60; }
    if (this.formAggregation === '1h') { return 60 * 60; }
    if (this.formAggregation === '24h') { return 24 * 60 * 60; }
    return 180;
  }

  get period() {
    const form = this.form;
    const d1 = date.extractDate(form.dt_from, 'YYYY/MM/DD');
    const d2 = date.extractDate(form.dt_to, 'YYYY/MM/DD');
    return date.getDateDiff(d2, d1, 'seconds');
  }

  get formValid() {
    const form = this.form;
    if (!form.dt_from || !form.dt_to) {
      return false;
    }
    if (this.period <= 0) {
      return false;
    }
    if ([...form.building_ids, ...form.level_ids, ...form.area_ids].length === 0) {
      return false;
    }
    return true;
  }

  get form() {
    return {
      dt_from: this.formDtFrom,
      dt_to: this.formDtTo,
      aggregation: this.formAggregation || null,
      export_type: this.formExportType,
      pollutants: this.formPollutants,
      building_ids: this.buildingIds,
      level_ids: this.levelIds,
      area_ids: this.areaIds,
    };
  }

  public getTreeData(prefix: string): number[] {
    return this.treeTicked.filter(
      (value: string) => value.startsWith(prefix + ':')).map(
        (value: string) => value.split(':')[1]).map(
          (value: string) => parseInt(value, 10));
  }

  get buildingIds() {
    return this.getTreeData('building');
  }

  get levelIds() {
    return this.getTreeData('level');
  }

  get areaIds() {
    return this.getTreeData('area');
  }

  get nodes() {
    return this.$store.state.buildings.map((building: any) => {
      return {
        label: building.name,
        value: `building:${building.id}`,
        children: building.levels.map((level: any) => {
          return {
            label: level.name,
            value: `level:${level.id}`,
            children: level.areas.map((area: any) => {
              return {
                value: `area:${area.id}`,
                label: area.name,
              };
            }),
          };
        }).filter((level: any) => level.children.length > 0),
      };
    }).filter((building: any) => building.children.length > 0);
  }

  public formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public createExport() {
    this.createExportLoading = true;
    axios.put('/data/export', this.form).then((response: any) => {
      this.createExportLoading = false;
      this.createForm = false;
      this.refreshDataExports();
    }).catch((error: any) => {
      this.createExportLoading = false;
      this.globalError(error);
    });
  }

  public getDownloadLink(dataExportId: string, filename: string) {
    return `${config.api_url}/data/export/${dataExportId}/download?filename=${filename}`;
  }

  public dateFormat(dateStr: string) {
    return dateStr.split('T')[0];
  }

  public waitingExport() {
    return this.dataExports.filter((dataExport: any) => dataExport.status === 'created' || dataExport.status === 'processing');
  }

  public haveWaitingExport() {
    return this.waitingExport().length > 0;
  }

  public checkExports() {
    for (const dataExport of this.waitingExport()) {
      setTimeout(() => {
        this.refreshDataExport(dataExport.id);
      }, 1000);
    }
  }

  public refreshDataExport(dataExportId: string) {
    apiGet(`/data/export/${dataExportId}`).then((response: any) => {
      // merge response with this.dataExports on id
      this.dataExports = this.dataExports.map((dataExport: any) => {
        if (dataExport.id === response.id) {
          return response;
        }
        return dataExport;
      });

      if (response.status === 'created' || response.status === 'processing') {
        setTimeout(() => {
          this.refreshDataExport(dataExportId);
        }, 1000);
      }
    });
  }

  public refreshDataExports() {
    apiGet('/data/export').then((response: any) => {
      this.dataExports = response.exports;
      this.checkExports();
    });
  }

  public created() {
    console.log(this.$route.params)
    if (this.$route.params.selectAll === true) {
      this.createForm = true;
      this.treeTicked = this.$store.state.buildings.map((building: any) => {
        return building.levels.map((level: any) => {
          return level.areas.map((area: any) => `area:${area.id}`);
        }).flat();
      }).flat();
    }
    if (this.$route.params.areaIds !== undefined) {
      this.treeTicked = this.$route.params.areaIds.map((areaId: number) => `area:${areaId}`);
      this.createForm = true;
    }
    if (this.$route.params.pollutants !== undefined) {
      this.formPollutants = this.$route.params.pollutants;
      this.createForm = true;
    }
    if (this.$route.params.dtFrom !== undefined) {
      this.formDtFrom = this.$route.params.dtFrom.toISOString().split('T')[0];
      this.formDtTo = this.$route.params.dtTo.toISOString().split('T')[0];
      this.createForm = true;
    }
    this.refreshDataExports();
  }

  public toDays(dateStr: string) {
    const d = date.extractDate(dateStr, 'YYYY-MM-DDTHH:mm:ss');
    return date.getDateDiff(d, new Date(), 'days');
  }

  public toggleDetails(dataExportId: string) {
    if (this.exportWithDetails[dataExportId] === undefined) {
      this.$set(this.exportWithDetails, dataExportId, true);
    } else {
      this.$set(this.exportWithDetails, dataExportId, !this.exportWithDetails[dataExportId]);
    }
  }
}
