











import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({

})
export default class CCCallout extends Vue {
  @Prop({ type: String, default: 'default' }) icon!: string;
}
